require('./bootstrap');
require("@grubersjoe/slide-menu");
import {Tooltip, Toast, Popover} from 'bootstrap';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

$(document).ready(function() {
    const menuElement = document.getElementById('slide-menu');
    const allEvents = [
        'sm.back',
        'sm.open',
        'sm.close'
    ];

    allEvents.forEach(eventName => {
        menuElement.addEventListener(eventName, event);
    });

    const menu = new SlideMenu(menuElement, {
        position: 'left',
        submenuLinkAfter: ' <i class="fas fa-chevron-right float-right mt-1"></i>',
        backLinkBefore: '<i class="fas fa-chevron-left mr-2"></i> ',
    });



    menuElement.addEventListener('sm.open', function () {
        $('html').addClass('overflow-hidden');
        $('body').addClass('overflow-hidden');
        $('.overlay').removeClass('d-none')
    });

    menuElement.addEventListener('sm.close', function () {
        $('html').removeClass('overflow-hidden');
        $('body').removeClass('overflow-hidden');
        $('.overlay').addClass('d-none')
    });

})
